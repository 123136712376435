<template>
  <main class="projects-view" v-if="API_REQUEST_STATE">
    <section class="projects-side">
      <section class="user-info">
        <UserInfo
          :projectsLength="projects.length"
          :unitsCount="getTotalUnitsCount"
          :userDetails="userDetails"
        />
      </section>

      <section class="new-project"><NewProject /></section>

      <section class="projects-summary">
        <ProjectsSummary :projectsLength="projects.length" />
      </section>
    </section>

    <section class="projects-list">
      <section class="welcome">
        <h4 class="-h4">Welcome,</h4>
        <p class="-ntr" v-if="projects.length">{{ welcomeMsg }}</p>
        <p class="-ntr" v-else>{{ emptyWelcomeMsg }}</p>
      </section>
      <ProjectsListContainer
        :projects="projects"
        @deleteProject="deleteProject"
      />
    </section>
  </main>
</template>

<script>
import UserInfo from "../components/projects/UserInfo.vue";
import ProjectsListContainer from "../components/projects/ProjectsListContainer.vue";
import NewProject from "../components/projects/NewProject.vue";
import ProjectsSummary from "../components/projects/ProjectsSummary.vue";
import { getProjects } from "../services/requests/get/projects";
import { deleteProject } from "../services/requests/post/projects";

export default {
  components: {
    UserInfo,
    ProjectsListContainer,
    NewProject,
    ProjectsSummary,
  },
  data() {
    return {
      projects: [],
      userDetails: null,
      welcomeMsg: "Select one of your projects",
      emptyWelcomeMsg: 'Select "New Project" to start working',
      API_REQUEST_STATE: false,
    };
  },
  async created() {
    const res = await getProjects();
    console.log("PROJECTS: ", res);
    this.API_REQUEST_STATE = true;

    if (res.status != 200) return;

    this.setUserInfo(res.data.projects, res.data.user[0].user_details);
  },
  methods: {
    setUserInfo(projects, userDetails) {
      this.projects = projects;
      this.userDetails = userDetails;
      console.log("USER DETAILS: ", userDetails);
    },
    async deleteProject(id) {
      const res = await deleteProject(id);

      if (res === 200) {
        //removes project with same id as parameter passed to function
        this.projects = this.projects.filter(
          (project) => project.proj_id != id
        );
      }
    },
  },
  computed: {
    getTotalUnitsCount() {
      if (!this.projects.length) return 0;

      return this.projects
        .map((project) => project.proj_units_count)
        .reduce((a, b) => a + b);
    },
  },
};
</script>

<style scoped>
.projects-view {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 1rem;
  padding-inline: 4px;
  padding-top: 4px;
  --projects-side-width: 240px;
}
.projects-side {
  width: var(--projects-side-width);
  display: flex;
  flex-flow: column;
  gap: 1rem;
}
.projects-list {
  width: calc(100% - var(--projects-side-width));
  display: flex;
  flex-flow: column;
  gap: 1rem;
}

.user-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  align-self: start;
  padding-top: 4px;
}
.searchbar-container {
  width: 240px;
  height: 40px;
}
.welcome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-inline: 1rem;
}
.welcome p {
  padding-block: 0.5rem;
}

@media screen and (max-width: 1024px) {
  .projects-view {
    display: flex;
    flex-direction: column;
    padding: 32px;
    overflow-x: hidden;
    gap: 32px;
  }
  .projects-side {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1rem;
  }
  .projects-list {
    width: 100%;
  }
  .user-info {
    flex-direction: row;
    grid-column: span 6;
    order: 2;
    justify-content: space-evenly;
    gap: 16px;
  }
  .searchbar-container {
    order: 6;
    align-self: center;
  }
  .projects-summary {
    order: 5;
    align-self: center;
  }
  .projects-list {
    order: 7;
    align-self: center;
  }
  .new-project {
    order: 8;
    align-self: center;
  }
}
</style>